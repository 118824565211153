/* You can add global styles to this file, and also import other style files */
@import url("../node_modules/bootstrap/dist/css/bootstrap.min.css");

.modal-backdrop {
    background-color: #000;
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 48 !important;
}

.custom-dialog {
    float: right;
    height: 100vh;
    margin: 0;
    max-height: 100vh;
    max-width: 50%;
    position: relative;
    width: 100%;
}

.medium-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
}

.large-dialog {
    max-width: 750px;
    margin: 1.75rem auto;

    @media (max-height: 700px) {
        max-width: 750px;
        margin: 1.75rem auto;
        height: 70vh;
    }
}

.big-dialog {
    max-width: 800px;
    margin: 1.75rem auto;
}

.big-large-dialog {
    max-width: 80%;
    margin: 1.75rem auto;
}

.extra-dialog {
    max-width: 90%;
    margin: 1.75rem auto;
}

.extra-big-dialog {
    width: 100%;
    max-width: 100% !important;
    margin: 1.75rem auto;
}

.all-screen {
    background-clip: padding-box;
    background-color: #fff;
    border-radius: 0rem;
    border: 1px solid rgba(0, 0, 0, .2);
    display: flex;
    flex-direction: column;
    height: inherit;
    outline: 0;
    pointer-events: auto;
    position: relative;
    width: 100%;
}

.modal-header {
    align-items: center;
    border-bottom: none;
    border-top-left-radius: calc(.3rem - 1px);
    border-top-right-radius: calc(.3rem - 1px);
    display: flex;
    justify-content: center;
    padding: 1rem 1rem;
}

.modal-body {
    background: white;
    flex: 1 1 auto;
    padding: 1rem;
    position: relative;
}

.modal-header .close {
    padding: 1rem 1rem;
    margin: 0;
    right: 10px;
    position: absolute;
}

.close:not(:disabled):not(.disabled):focus,
.close:not(:disabled):not(.disabled):hover {
    opacity: .75;
    color: red;
}

.placeholder-text {
    background-attachment: fixed;
    background-color: #eee;
    background-image: url('/assets/images/phloader.gif');
    background-repeat: repeat-y;
    background-size: 100% 1px;
    border-radius: 3px;
    display: inline-block;
    height: 10px;
    width: 50px;
}

.title {
    color: #00e07b !important;
    font-size: 20px !important;
    font-weight: bold !important;
    margin: 0 !important;
}

.subtitle {
    color: #aaa !important;
    font-size: 16px !important;
    font-weight: bolder !important;
    margin: 0 !important;
}

.btn-green {
    background: #00e07b;
    border: none;
    border-radius: 30px;
    color: white;
    padding: 8px 16px;

    &.large {
        margin-left: 0%;
        width: 100%;
    }

    &.medium {
        margin-left: 25%;
        width: 50%;
        margin-bottom: 3%;
    }
}

.btn-red {
    background: #CD2120 !important;
    border: none !important;
    border-radius: 30px !important;
    color: white !important;
    padding: 8px 16px !important;

    &.large {
        margin-left: 0%;
        width: 100%;
    }

    &.medium {
        margin-left: 12.5%;
        width: 75%;
    }

    &.small {
        margin-left: 25%;
        width: 50%;
    }

    &:hover {
        border: none;
        border-radius: 30px;
        color: white;
        padding: 8px 16px;
    }
}

button:focus {
    outline: 0;
}

.search {
    align-items: center;
    background: #fafafa;
    border: none;
    display: grid;
    grid-template-columns: 10% 90%;
    grid-template-areas: "icon text";
    width: 220px;

    .icon {
        color: #626262;
    }

    .text {
        background: #fafafa;
        border: none;
    }
}

label {
    color: #626262;
    font-size: 14px;
    font-weight: 700;
}

input,
textarea {
    color: #626262;
    font-size: 14px;
    font-weight: 700;
}

select {
    background: white !important;
    color: #626262 !important;
    font-size: 12px !important;
    font-weight: bold !important;
    width: 50%;
}

table {
    width: 100%;
}

th {
    color: #1e1e1e !important;
    font-size: 14px !important;
    font-weight: bold !important;
    padding: 5px !important;
}

td {
    color: #626262 !important;
    font-weight: 400 !important;
    font-size: .9em !important;
    padding: 0px !important;
}

td.without {
    color: #626262 !important;
    font-weight: 400 !important;
    font-size: .9em !important;
    padding: 0px !important;
}

tr {
    border-bottom: 1px solid rgba(230, 230, 230, .7);

    &.noline {
        border-bottom: none;
    }
}

.card {
    background: white !important;
    border: 1px solid transparent !important;
    border-bottom: 0 !important;
    border-left: 0 !important;
    border-radius: 4px !important;
    border-right: 0 !important;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15), 0 0 1px rgba(0, 0, 0, 0.08) !important;
    margin: 0 !important;
    margin-bottom: 0% !important;
    margin-left: 1.5% !important;
    width: 97.5% !important;

    @media (max-width: 967px) {
        margin-left: 1.5% !important;
        margin-bottom: 2% !important;
        width: 97.5% !important;
    }
}

.card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;

    @media (max-width: 967px) {
        padding: 10px;
    }
}

.container {
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;

    @media (max-width: 967px) {
        padding-right: 0px;
        padding-left: 0px;
    }
}

.ng-draggable {
    cursor: grab;
}

.ng-dragging {
    cursor: grabbing;
}

.tooltip .tooltip-content,
.epyc-tooltip .tooltip-content {
    font-size: 14px;
    position: fixed;
}

.epyc-tooltip,
.epy-tooltip {
    outline: none;
    position: relative;
    width: 100%;

    & .tooltip-trigger {
        text-align: center;
        width: auto !important;
    }
}

.pricing-columns-data {

    .epyc-tooltip,
    .epy-tooltip {
        outline: none;
        position: relative;
        width: 100%;

        & .tooltip-trigger {
            text-align: left;
            width: auto !important;
        }

        & .tooltip-content {
            background-color: #1e2228;
            border-radius: 4px;
            box-shadow: 0 2px 2px 0 rgba(30, 34, 40, 0.5);
            color: #fff;
            display: block;
            padding: 4px 16px;
            position: absolute;
            transition: opacity 0.5s;
            width: max-content;
            z-index: 100;
        }
    }
}

.epyc-tooltip,
.epy-tooltip {
    outline: none;
    position: relative;
    width: 100%;

    & .tooltip-trigger {
        width: auto !important;
    }

    & .tooltip-content {
        background-color: #1e2228;
        color: #fff;
    }
}

.table {
    margin-bottom: 0rem !important;
}

progress {
    vertical-align: bottom;
}

.expand-sidebar {
    -webkit-box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
    background: #1a1e25;
    box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
    height: 100%;
    padding-top: 50px;
    top: 0px;
    width: 260px;
    z-index: 20;
}

.table td,
.table th {
    padding: .75rem;
    vertical-align: middle !important;
    border-top: 1px solid #dee2e6;
    padding: 5px !important;

    @media (max-width: 967px) {
        font-size: 12px !important;
    }

    span {
        @media (max-width: 967px) {
            font-size: 12px;
        }
    }

    p {
        @media (max-width: 967px) {
            font-size: 12px;
        }
    }
}

.ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell {
    align-items: center !important;
    background: white;
    border-bottom: none;
    color: black;
    display: flex !important;
    font-weight: bold;
    justify-content: center;
    overflow: hidden;
    padding: 0.25rem 0.1rem;
    text-align: center !important;
    text-overflow: ellipsis;
    white-space: nowrap;

    &.regular {
        color: #159546;
    }

    &.premium {
        color: #ea0000;
    }

    &.diesel {
        color: #1e2228;
    }
}

.ngx-datatable.fixed-row .datatable-scroll .datatable-body-row .datatable-body-cell,
.ngx-datatable.fixed-row .datatable-scroll .datatable-body-row .datatable-body-group-cell {
    align-items: center !important;
    cursor: pointer;
    display: flex !important;
    font-size: 1em;
    justify-content: center;
    overflow: hidden;
    padding: 0.25rem 0.1rem !important;
    text-align: center !important;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: initial;
}

.ngx-datatable .datatable-row-center,
.ngx-datatable .datatable-row-group,
.ngx-datatable .datatable-row-left,
.ngx-datatable .datatable-row-left {
    position: relative;
    display: flex;
}

.ngx-datatable.bootstrap .datatable-body .datatable-body-row.active {
    background-color: #124187 !important;
    border-bottom: none !important;
    border-top: none !important;
    color: #124187 !important;
    cursor: pointer;
    font-weight: bold;
}

.right-side {
    border-right: 2px solid rgba(0, 0, 0, .25);

    &.total-text {
        border-right: 2px solid #072446;
        font-weight: bold;
        color: #072446 !important;
    }

    &.regular {
        border-width: 3px;
        border-color: #159546;
    }

    &.premium {
        border-width: 3px;
        border-color: #ea0000;
    }

    &.diesel {
        border-width: 3px;
        border-color: #1e2228;
    }
}

.left-side {
    border-left: 2px solid rgba(0, 0, 0, .25);

    &.total-text {
        border-left: 2px solid #072446;
        font-weight: bold;
        color: #072446 !important;
    }

    &.regular {
        border-width: 3px;
        border-color: #159546;
    }

    &.premium {
        border-width: 3px;
        border-color: #ea0000;
    }

    &.diesel {
        border-width: 3px;
        border-color: #1e2228;
    }
}

.center-side {
    &.total-text {
        font-weight: bold;
        color: #072446 !important;
    }

    &.regular {
        border-width: 3px;
        border-color: #159546;
    }

    &.premium {
        border-width: 3px;
        border-color: #ea0000;
    }

    &.diesel {
        border-width: 3px;
        border-color: #1e2228;
    }
}

.top-side {
    border-top: 2px solid rgba(0, 0, 0, .25);
    border-bottom: 2px solid rgba(0, 0, 0, .25) !important;

    &.regular {
        border-width: 3px;
        border-color: #159546;
    }

    &.premium {
        border-width: 3px;
        border-color: #ea0000;
    }

    &.diesel {
        border-width: 3px;
        border-color: #1e2228;
    }
}

.top-side-only {
    border-top: 1px solid rgba(0, 0, 0, .25);
    border-bottom: none !important;

    &.total-text {
        border-top: 2px solid #072446 !important;
        font-weight: bold;
        color: #072446 !important;
    }

    &.regular {
        border-width: 3px;
        border-color: #159546;
    }

    &.premium {
        border-width: 3px;
        border-color: #ea0000;
    }

    &.diesel {
        border-width: 3px;
        border-color: #1e2228;
    }
}

.bottom-side {
    border-top: none !important;
    border-bottom: 2px solid rgba(0, 0, 0, .25) !important;

    &.total-text {
        border-bottom: 3px solid #072446 !important;
        font-weight: bold;
        color: #072446 !important;
    }
}

.ngx-datatable .datatable-row-center,
.ngx-datatable .datatable-row-group,
.ngx-datatable .datatable-row-left,
.ngx-datatable .datatable-row-right {
    position: relative;
    background-color: white;
}

.ngx-datatable {

    .bg-chevron {
        background: #deeaf7 !important;
    }

    .bg-conoco {
        background: #f4b084 !important;
    }

    .bg-shell {
        background: #FED301 !important;
    }

    .bg-sg {
        background: #204070 !important;
        color: white !important;
    }

    .bg-flash {
        background: #fe5f00 !important;
    }

    .bg-bp {
        background: #9ACD00 !important;
    }

    .bg-gas {
        background: #c6e0b4 !important;
    }

    .bg-total {
        background: #002060 !important;
        color: white !important;
        font-weight: bold;
    }

    .bg-enerser {
        color: black !important;
        background: #f5f5f5 !important;
        font-weight: bold;
    }

    .l-gray {
        color: black !important;
        background: #f5f5f5 !important;
        border: none !important;
    }

    .l-gray-t {
        color: black !important;
        background: #f5f5f5 !important;
        border: none !important;
        border-top: 2px solid #f5f5f5 !important;
    }

    .l-gray-tbl {
        color: black !important;
        background: #e7e7e7 !important;
        border: none !important;
        border-top: 2px solid #d1d4d7 !important;
        border-bottom: 2px solid #d1d4d7 !important;
        border-left: 2px solid #d1d4d7 !important;
    }

    .t-gray-tbl {
        color: black !important;
        background: #e7e7e7 !important;
        border: none !important;
        border-top: 2px solid #d1d4d7 !important;
        border-bottom: 2px solid #d1d4d7 !important;
    }

    .r-gray-tbl {
        color: black !important;
        background: #e7e7e7 !important;
        border: none !important;
        border-top: 2px solid #d1d4d7 !important;
        border-bottom: 2px solid #d1d4d7 !important;
        border-right: 2px solid #d1d4d7 !important;
    }

    .l-blue-tbl {
        color: black !important;
        background: #C6E9FF !important;
        border: none !important;
        border-top: 2px solid #d1d4d7 !important;
        border-bottom: 2px solid #d1d4d7 !important;
        border-left: 2px solid #d1d4d7 !important;
    }

    .t-blue-tbl {
        color: black !important;
        background: #C6E9FF !important;
        border: none !important;
        border-top: 2px solid #d1d4d7 !important;
        border-bottom: 2px solid #d1d4d7 !important;
    }

    .r-blue-tbl {
        color: black !important;
        background: #C6E9FF !important;
        border: none !important;
        border-top: 2px solid #d1d4d7 !important;
        border-bottom: 2px solid #d1d4d7 !important;
        border-right: 2px solid #d1d4d7 !important;
    }

    .l-blue-t {
        color: black !important;
        background: #bdd7ee !important;
        border: none !important;
        border-top: 2px solid #bdd7ee !important;
    }

    .l-gray-enerser-t {
        color: black !important;
        background: #f2f2f2 !important;
        border: none !important;
        border-top: 2px solid #f2f2f2 !important;
    }

    .l-gray-enerser-l {
        color: black !important;
        background: #bdd7ee !important;
        border: none !important;
        border-top: 2px solid black !important;
        border-left: 2px solid black !important;
    }

    .l-yellow-t {
        color: black !important;
        background: #ffbf00 !important;
        border: none !important;
        border-top: 2px solid #ffbf00 !important;
    }

    .l-yellow-r {
        color: black !important;
        background: #ffbf00 !important;
        border: none !important;
        border-top: 2px solid black !important;
        border-right: 2px solid black !important;
    }

    .l-green-r {
        color: black !important;
        background: #c6e0b4 !important;
        border: none !important;
        border-top: 2px solid black !important;
    }

    .l-green-tr {
        color: black !important;
        background: #c6e0b4 !important;
        border: none !important;
        border-top: 2px solid black !important;
        border-right: 2px solid black !important;
    }

    .l-green-t {
        color: black !important;
        background: #c6e0b4 !important;
        border: none !important;
        border-top: 2px solid #c6e0b4 !important;
    }

    .l-regular-t {
        color: white !important;
        background: #ff0000 !important;
        border: none !important;
    }

    .l-supreme-t {
        color: black !important;
        background: #bfbfbf !important;
        border: none !important;
    }

    .l-diesel-t {
        color: white !important;
        background: black !important;
        border: none !important;
    }

    .l-cambio-t {
        color: black !important;
        background: #ff8f07 !important;
        border: none !important;
    }

    .l-regular-b {
        color: white !important;
        background: #ff0000 !important;
        border: none !important;
        border-left: 2px solid black !important;
    }

    .l-diesel-b {
        color: white !important;
        background: black !important;
        border: none !important;
        border-right: 2px solid black !important;
    }

    .fsct {
        color: black !important;
        background: #ffd966 !important;
        border: none !important;
    }

    .fsct-l {
        color: black !important;
        background: #ffd966 !important;
        border: 2px solid rgba(0, 0, 0, 0.25) !important;
        border-bottom: none !important;
        border-top: none !important;
    }

    .l-green {
        color: black !important;
        background: #BAC1C9 !important;
        border: none !important;
    }

    .l-yellow {
        color: white !important;
        background: #7E8D9E !important;
        border: none !important;
    }

    .l-red {
        color: white !important;
        background: #435872 !important;
        border: none !important;
    }

    .l-blue {
        color: white !important;
        background: #072446 !important;
        border: none !important;
    }

    .bg-green {
        background: #afed8e !important;
        background-color: #afed8e !important;
        font-weight: bold;
        border: none !important;
    }

    .bg-yellow {
        background: #ffd966 !important;
        background-color: #ffd966 !important;
        font-weight: bold;
        border: none !important;
    }

    .bg-blue {
        background: #82cfff !important;
        background-color: #82cfff !important;
        font-weight: bold;
        border: none !important;
    }

    .border-sides {
        border-left: 2px solid black !important;
        border-right: 2px solid black !important;
    }

    .border-left {
        border-left: 2px solid black !important;
    }

    .border-right {
        border-right: 2px solid black !important;
    }

    .border-top {
        border-top: 2px solid black !important;
        border-left: 2px solid black !important;
        border-right: 2px solid black !important;
    }

    .border-top-w {
        border-top: 2px solid white !important;
    }

    .border-bottom {
        border-bottom: 2px solid black !important;
        border-left: 2px solid black !important;
        border-right: 2px solid black !important;
    }

    .border-bot {
        border-bottom: 2px solid black !important;
    }

    .l-gray-ligth-border {
        color: white !important;
        background: #435872 !important;
    }

    .l-gray-border {
        color: black !important;
        background: #bfbfbf !important;
    }

    .l-gray-dark-border {
        color: white !important;
        background: #7E8D9E !important;
    }

    .esmexico-yellow {
        color: black !important;
        background: #ffff00 !important;
    }

    .esmexico-orange {
        color: black !important;
        background: #ed7d32 !important;
    }

    .esmexico-beige {
        color: black !important;
        background: #fee6a5 !important;
    }

    .esmexico-blue {
        color: black !important;
        background: #06b0f1 !important;
    }

    .esmexico-blue-gray {
        color: black !important;
        background: #b4c6e7 !important;
    }

    .esmexico-gray {
        color: black !important;
        background: #dddbd5 !important;
    }

    .esmexico-green {
        color: black !important;
        background: #6efc32 !important;
    }

    .esmexico-green-b {
        color: black !important;
        background: #6baa4d !important;
    }

    .esmexico-blue-b {
        color: black !important;
        background: #83cfff !important;
    }



    // .l-green-border {
    //     color: black !important;
    //     background: #c2ebd1 !important;
    // }

    // .l-yellow-border {
    //     color: black !important;
    //     background: #fff3cf !important;
    // }

    // .l-orange-border {
    //     color: black !important;
    //     background: #fde2cd !important;
    // }

    // .l-pink-border {
    //     color: black !important;
    //     background: #f8d7e8 !important;
    // }
}

.ngx-datatable.bootstrap .datatable-body .datatable-body-row .datatable-body-cell {
    padding: 0 !important;
}

.ngx-datatable .datatable-row-left {
    z-index: 9;

    .blue {
        color: white !important;
        background: #072446 !important;
        border: none !important;
        border-bottom: 1px solid white !important;
    }

    .black {
        color: white !important;
        background: black !important;
        border: none !important;
    }

    .white {
        color: black !important;
        background: white !important;
        border-left: none !important;
    }

    .blue-green {
        color: white !important;
        background: #077E36 !important;
        border: none !important;
    }

    .green-blue {
        color: white !important;
        background: #055d6b !important;
        border: none !important;
    }

    .green {
        color: white !important;
        background: #099e01 !important;
        border: none !important;
    }

    .green-gray {
        color: white !important;
        background: #31952C !important;
        border: none !important;
    }

    .gray {
        color: white !important;
        background: #828282 !important;
        border: none !important;
    }

    .ligth-gray {
        color: black !important;
        background: #d1d1d1 !important;
        border: none !important;
    }
}

.national {

    .ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 0 !important;
        border: 0 !important;
    }

    .ngx-datatable .datatable-header .datatable-header-cell .datatable-header-cell-template-wrap {
        height: inherit;
        width: 100% !important;
    }
}

.ngx-datatable.bootstrap.national {
    z-index: 9;

    .blue {
        color: white !important;
        background: #072446 !important;
        border: none !important;
        border-bottom: 1px solid white !important;
    }

    .black {
        color: white !important;
        background: black !important;
        border: none !important;
    }

    .white {
        color: black !important;
        background: white !important;
        border-left: none !important;
    }

    .blue-green {
        color: white !important;
        background: #077E36 !important;
        border: none !important;
    }

    .green-blue {
        color: white !important;
        background: #055d6b !important;
        border: none !important;
    }

    .green {
        color: white !important;
        background: #099e01 !important;
        border: none !important;
    }

    .green-gray {
        color: white !important;
        background: #31952C !important;
        border: none !important;
    }

    .gray {
        color: white !important;
        background: #828282 !important;
        border: none !important;
    }

    .ligth-gray {
        color: black !important;
        background: #d1d1d1 !important;
        border: none !important;
    }
}

.bg-ligthblue {
    background: #e8e8e8 !important;
    background-color: #e8e8e8 !important;
    font-weight: bold;
}

.bg-bottom {
    background: #efefef !important;
    background-color: #efefef !important;
}

.bg-top {
    background: #bfe8fe !important;
    background-color: #bfe8fe !important;
}

.bg-green {
    background: #afed8e !important;
    background-color: #afed8e !important;
    font-weight: bold;
}

.bg-yellow {
    background: #ffd966 !important;
    background-color: #ffd966 !important;
    font-weight: bold;
}

.bg-blue {
    background: #82cfff !important;
    background-color: #82cfff !important;
    font-weight: bold;
}

.ngx-datatable,
.datatable-body-cell-label {
    text-align: center !important;
    width: 100%;
}

.white-space {
    background: white;
}

.ngx-datatable {
    overflow: visible !important;
}

.ngx-datatable.bootstrap .datatable-body .datatable-body-row.datatable-row-even {
    border-bottom: -1px solid rgba(0, 0, 0, .25);
}

.ngx-datatable.bootstrap .datatable-body .datatable-body-row.datatable-row-odd {
    border-bottom: -1px solid rgba(0, 0, 0, .25);
}

.ngx-datatable.scroll-vertical.virtualized .datatable-body .datatable-row-wrapper {
    position: absolute;
    border-bottom: -1px solid rgba(0, 0, 0, .25);
}

.custom-class-example {
    color: #ccc;
}

.custom-class-example .c-token {
    background: #38d574 !important;
}

.custom-class-example .pure-checkbox label::before {
    border-color: #38d574 !important;
}

.custom-class-example .pure-checkbox input[type="checkbox"]:checked+label[_ngcontent-c1]:before {
    background: #38d574 !important;
}

.margin-c {

    .epyc-tooltip .tooltip-trigger,
    .epy-tooltip .tooltip-trigger {
        text-align: left;
    }
}

/* in-flight clone */
.gu-mirror {
    position: fixed !important;
    margin: 0 !important;
    z-index: 9999 !important;
    opacity: 0.8;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
    filter: alpha(opacity=80);
    pointer-events: none;
}

/* high-performance display:none; helper */
.gu-hide {
    left: -9999px !important;
}

/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
}

/* added to the source element while its mirror is dragged */
.gu-transit {
    opacity: 0.2;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
    filter: alpha(opacity=20);
}

.ngx-datatable.scroll-horz .datatable-body {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
}

.ngx-datatable.bootstrap.market {
    .datatable-body .datatable-scroll {
        display: inline-block;
        max-height: calc(82vh - 80px) !important;
    }

    .datatable-body .datatable-body-row.active {
        background-color: inherit !important;
        border-bottom: none !important;
        border-top: none !important;
        cursor: pointer;
        font-weight: bold;
    }
}

.ngx-datatable.bootstrap.cross {
    .datatable-body .datatable-scroll {
        display: inline-block;
        max-height: calc(82vh - 80px) !important;
        overflow: scroll;
    }

    .datatable-body .datatable-body-row.active {
        background-color: inherit !important;
        border-bottom: none !important;
        border-top: none !important;
        cursor: pointer;
        font-weight: bold;
    }
}

.ngx-datatable.bootstrap.sales {
    .datatable-body .datatable-body-row.active {
        background-color: inherit !important;
        border-bottom: none !important;
        border-top: none !important;
        cursor: pointer;
        font-weight: bold;
    }
}

.ngx-datatable.bootstrap.market .datatable-body .datatable-body-row .datatable-body-cell {
    padding: 0 !important;
    overflow: unset !important;
    position: sticky !important;
    background: #fff;
    top: 0 !important;
    z-index: 8 !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
}

.ngx-datatable.bootstrap.market .datatable-header {
    height: unset !important;
    position: sticky !important;
    background: #fff;
    top: 0 !important;
    z-index: 100 !important;
}

.dataTables_filter input {
    width: 140px;
}

.input .input-container.content-left .input-text,
.epyc-input .input-container.content-left .input-text {
    padding-left: 14px;
    font-style: italic !important;
}

.text-suggest {
    color: #c7c7c7;
    font-size: 16px;
    line-height: 10px;
    margin-left: 15px;
    margin-top: 18px;
}

.market-intelligence {
    .ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell {
        align-items: center !important;
        background: #00e07b !important;
        border-bottom: none;
        color: white !important;
        display: flex !important;
        font-weight: bold;
        justify-content: center;
        overflow: hidden;
        padding: 0.25rem 0.1rem;
        text-align: center !important;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .row-gray {
        background: #dee2e6 !important;
    }

    .row-white {
        background: white !important;
    }

    .ngx-datatable.fixed-row .datatable-scroll .datatable-body-row .datatable-body-cell {
        color: black !important;
        background: inherit !important;
        border: none !important;
    }

    .ngx-datatable .datatable-row-center,
    .ngx-datatable .datatable-row-group,
    .ngx-datatable .datatable-row-left,
    .ngx-datatable .datatable-row-right {
        position: relative;
        background-color: inherit;
    }
}

.select.select-outline .select-trigger,
.epyc-select.select-outline .select-trigger {
    padding: 0 8px 0px 8px !important;
}

.select .select-details .option,
.epyc-select .select-details .option {
    padding: 8px !important;
}

.select-type-brands {

    .select.select-outline .select-trigger,
    .epyc-select.select-outline .select-trigger {
        align-items: center;
        box-shadow: 0 2px 2px 0 rgb(212 212 212 / 50%);
        color: #717171;
        cursor: pointer;
        display: flex;
        font-size: 14px;
        height: 30px;
        justify-content: space-between;
        line-height: 18px;
        min-width: -webkit-fit-content;
        min-width: -moz-fit-content;
        min-width: fit-content;
        position: relative;
        -webkit-box-shadow: 0 2px 2px 0 rgb(212 212 212 / 50%);
        background-color: transparent;
        border: 1px solid rgba(0, 0, 0, 0.15);
        border-radius: 4px;
        padding: 0 16px;
    }

    .select.select-line.active .select-trigger,
    .select.select-outline.active .select-trigger,
    .select.select-border-none.active .select-trigger,
    .epyc-select.select-line.active .select-trigger,
    .epyc-select.select-outline.active .select-trigger,
    .epyc-select.select-border-none.active .select-trigger {
        align-items: center;
        box-shadow: 0 2px 2px 0 rgb(212 212 212 / 50%);
        color: #717171;
        cursor: pointer;
        display: flex;
        font-size: 14px;
        height: 30px;
        justify-content: space-between;
        line-height: 18px;
        min-width: -webkit-fit-content;
        min-width: -moz-fit-content;
        min-width: fit-content;
        position: relative;
        -webkit-box-shadow: 0 2px 2px 0 rgb(212 212 212 / 50%);
    }

    .select .select-details .option,
    .epyc-select .select-details .option {
        border-bottom: 1px solid #f7f7f7;
        position: relative;
        display: block;
        padding: 2px 8px !important;
        font-size: 14px !important;
        line-height: 18px;
        color: #9b9b9b;
        cursor: pointer;
        transition: all 0.5s;
        margin: 1px 0;
        text-align: left;
    }

    .input .input-container .input-text,
    .epyc-input .input-container .input-text {
        background-color: transparent;
        border: none;
        font-style: italic !important;
        color: #717171;
        height: 30px;
        padding: 2px 8px !important;
        font-size: 14px !important;
        outline: none;
    }
}

.ngx-datatable.scroll-vertical .datatable-body {
    overflow-y: scroll !important;
}

.dropdown-item {
    display: block;
    width: 100%;
    font-size: 14px !important;
    padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: normal;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background: none;
    border: 0;
    cursor: pointer;
}

.dropdown-item:focus,
.dropdown-item:hover {
    background-color: #ededed;
    color: #00e07b !important;
    cursor: pointer;
    font-weight: bold;
    text-decoration: none;
}

.monitor {
    .ngx-select__toggle {
        outline: 0;
        position: relative;
        text-align: left !important;
        background-color: #fff;
        border-color: #ccc;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0;
        height: 30px;
        max-height: 30px;
        min-height: 24px;
        color: #626262 !important;
        font-weight: 700 !important;
        font-size: 14px !important;
        padding: 0px 6px !important;
        min-width: 136px !important;
        width: 136px !important;
    }

    .ngx-select__search.form-control {
        height: 30px;
        max-height: 30px;
        min-height: 24px;
        width: 136px !important;
    }

    .ngx-select__choices {
        width: 100%;
        height: auto;
        max-height: 200px;
        overflow-x: hidden;
        margin-top: 0;
        position: absolute;
        width: 136px !important;
    }
}

.status-type {
    .ngx-select__toggle {
        outline: 0;
        position: relative;
        text-align: left !important;
        background-color: #fff;
        border-color: #ccc;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0;
        font-size: 14px !important;
        padding: 0px 6px !important;
        min-width: 190px !important;
        width: 190px !important;
    }

    .ngx-select__search.form-control {
        width: 190px !important;
    }

    .ngx-select__choices {
        width: 100%;
        height: auto;
        max-height: 200px;
        overflow-x: hidden;
        margin-top: 0;
        position: absolute;
        width: 190px !important;
    }
}

.margin-type {
    .ngx-select__toggle {
        outline: 0;
        position: relative;
        text-align: left !important;
        background-color: #fff;
        border-color: #ccc;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0;
        font-size: 14px !important;
        padding: 0px 6px !important;
        min-width: 190px !important;
        width: 190px !important;
    }

    .ngx-select__search.form-control {
        width: 190px !important;
    }

    .ngx-select__choices {
        width: 100%;
        height: auto;
        max-height: 200px;
        overflow-x: hidden;
        margin-top: 0;
        position: absolute;
        width: 190px !important;
    }
}

.budget {
    .ngx-select__toggle {
        outline: 0;
        position: relative;
        text-align: center !important;
        font-size: 14px !important;
        background-color: transparent !important;
        border-color: black !important;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0;
        height: 20px;
        max-height: 20px;
        min-height: 20px;
        color: black !important;
        font-weight: 700 !important;
        font-size: 14px !important;
        padding: 0px 6px !important;
        min-width: 90px !important;
        width: 90px !important;
    }

    .ngx-select__search.form-control {
        height: 20px;
        max-height: 20px;
        min-height: 20px;
        font-size: 13px;
        color: black;
        width: 90px !important;
    }

    .dropdown-toggle {
        white-space: nowrap;
        color: black;
    }

    .ngx-select__selected-single {
        font-size: 13px;
        font-weight: bold;
    }

    .ngx-select__choices {
        width: 90px !important;
        height: auto;
        max-height: 200px;
        overflow-x: hidden;
        margin-top: 0;
        position: absolute;
    }

    .dropdown-menu {
        width: 90px !important;
        max-width: 90px !important;
    }
}

.budget-white {
    .ngx-select__toggle {
        outline: 0;
        position: relative;
        text-align: center !important;
        font-size: 14px !important;
        background-color: transparent !important;
        border-color: white !important;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0;
        height: 20px;
        max-height: 20px;
        min-height: 20px;
        color: white !important;
        font-weight: 700 !important;
        font-size: 0.9em !important;
        padding: 0px 6px !important;
        min-width: 90px !important;
        width: 90px !important;
    }

    .ngx-select__search.form-control {
        height: 20px;
        max-height: 20px;
        min-height: 20px;
        font-size: 13px;
        color: black;
        width: 90px !important;
    }

    .dropdown-toggle {
        white-space: nowrap;
        color: white;
    }

    .ngx-select__selected-single {
        font-size: 13px;
        font-weight: bold;
    }

    .ngx-select__choices {
        width: 90px !important;
        height: auto;
        max-height: 200px;
        overflow-x: hidden;
        margin-top: 0;
        position: absolute;
    }

    .dropdown-menu {
        width: 90px !important;
        max-width: 90px !important;
    }
}

.lvl-discount {
    margin-left: 3px;
    margin-right: 3px;

    .ngx-select__toggle {
        outline: 0;
        position: relative;
        text-align: left !important;
        color: #333;
        background-color: #fff;
        border-color: #ccc;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0;
        height: 30px;
        max-height: 30px;
        min-height: 24px;
        color: #626262 !important;
        font-weight: 700 !important;
        font-size: 0.9em !important;
        padding: 0px 6px !important;
        min-width: 86px !important;
        width: 86px !important;
    }

    .ngx-select__search.form-control {
        height: 30px;
        max-height: 30px;
        min-height: 24px;
        width: 86px !important;
    }

    .ngx-select__choices {
        width: 100%;
        height: auto;
        max-height: 200px;
        overflow-x: hidden;
        margin-top: 0;
        position: absolute;
        width: 86px !important;
    }
}

.reason {

    ngx-select.reason {
        display: inline-block;
        width: 160px !important;
    }

    .ngx-select__clear {
        margin-right: 10px;
        padding: 0;
        border: none;
        height: auto !important;
        text-align: right;
        min-width: 300px !important;
    }

    .ngx-select__toggle {
        outline: 0;
        position: relative;
        text-align: left !important;
        color: #333;
        background-color: #fff;
        border-color: #ccc;
        display: flex;
        align-items: center !important;
        justify-content: space-between;
        margin: 0;
        min-width: 160px !important;
        width: 160px !important;
        border: none;
        font-size: 14px !important;
    }

    .ngx-select__choices {
        width: 270px !important;
        height: auto;
        max-height: 184px;
        overflow-x: hidden;
        margin-top: 0;
        position: absolute;
    }

    .ngx-select__toggle:hover {
        background-color: #ededed !important;
        color: #00e07b !important;
        border-color: #adadad;
    }

    .ngx-select__item {
        font-size: 14px;
        min-width: 270px !important;
        width: 270px !important;
    }

    .ngx-select__item_active {
        background-color: #ededed !important;
        color: #00e07b !important;
        cursor: pointer;
        font-size: 14px;
        font-weight: bold;
        outline: 0;
        min-width: 270px !important;
        width: 270px !important;
    }

    ngx-select-choices {
        min-width: 270px !important;
        width: 270px !important;
    }

    .dropdown-menu {
        min-width: 270px !important;
        width: 270px !important;
    }

    .form-control {
        border: none;
        font-size: 14px;
        width: 160px !important;
    }

    .form-control:focus {
        box-shadow: none !important;
    }

}

.variables-dashboard {

    ngx-select.variables-dashboard {
        display: inline-block;
        min-width: 194px !important;
        width: 194px !important;
    }

    .ngx-select__clear {
        margin-right: 10px;
        padding: 0;
        height: auto !important;
        text-align: right;
        min-width: 300px !important;
    }

    .ngx-select__toggle {
        outline: 0;
        position: relative;
        text-align: left !important;
        color: #333;
        background-color: #fff;
        border: 1px solid #ccc !important;
        display: inline-flex;
        align-items: stretch;
        justify-content: space-between;
        min-width: 184px !important;
        width: 184px !important;
        margin: 0;
    }

    .ngx-select__choices {
        width: 100px !important;
        height: auto;
        max-height: 184px;
        overflow-x: hidden;
        margin-top: 0;
        position: absolute;
    }

    .ngx-select__toggle:hover {
        background-color: #ededed !important;
        color: #00e07b !important;
        border-color: #adadad;
    }

    .ngx-select__item {
        font-size: 14px;
        min-width: 184px !important;
        width: 184px !important;
    }

    .ngx-select__item_active {
        background-color: #ededed !important;
        color: #00e07b !important;
        cursor: pointer;
        font-size: 14px;
        font-weight: bold;
        outline: 0;
        min-width: 184px !important;
        width: 184px !important;
    }

    ngx-select-choices {
        min-width: 184px !important;
        width: 184px !important;
    }

    .dropdown-menu {
        min-width: 184px !important;
        width: 184px !important;
    }

    .form-control {
        width: 184px !important;
    }

    .form-control:focus {
        box-shadow: none !important;
    }

}

.volume-type {

    .form-control {
        color: #67757c;
        min-height: 30px;
        display: initial;
    }

    ngx-select.volume-type {
        display: inline-block;
        width: 100px !important;
        max-height: 30px !important;
    }

    .ngx-select__clear {
        margin-right: 10px;
        padding: 0;
        border: none;
        height: auto !important;
        text-align: right;
        min-width: 100px !important;
    }

    .ngx-select__toggle {
        outline: 0;
        position: relative;
        text-align: left !important;
        color: #333;
        background-color: #fff;
        border-color: #ccc;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 0.8em;
        margin: 0;
        min-width: 100px !important;
        width: 100px !important;
        padding: 4px 8px;
        max-height: 30px !important;
    }

    .ngx-select__search.form-control {
        width: 100px;
        max-height: 30px !important;
        font-size: .8em;
    }

    .ngx-select__choices {
        width: 100px !important;
        height: auto;
        max-height: 200px;
        overflow-x: hidden;
        margin-top: 0;
        position: absolute;
        font-size: .8em;
    }

    .ngx-select__item {
        min-width: 100px !important;
        width: 100px !important;
    }
}

.zone {

    ngx-select.zone {
        display: inline-block;
        width: 100px !important;
    }

    .ngx-select__clear {
        margin-right: 10px;
        padding: 0;
        border: none;
        height: auto !important;
        text-align: right;
        min-width: 300px !important;
    }

    .ngx-select__toggle {
        outline: 0;
        position: relative;
        text-align: left !important;
        color: #333;
        background-color: #fff;
        border-color: #ccc;
        display: flex;
        align-items: center !important;
        justify-content: space-between;
        margin: 0;
        min-width: 100px !important;
        width: 100px !important;
        border: none;
    }

    .ngx-select__choices {
        width: 190px !important;
        height: auto;
        max-height: 184px;
        overflow-x: hidden;
        margin-top: 0;
        position: absolute;
    }

    .ngx-select__toggle:hover {
        background-color: #ededed !important;
        color: #00e07b !important;
        border-color: #adadad;
    }

    .ngx-select__item {
        min-width: 190px !important;
        width: 190px !important;
    }

    .ngx-select__item_active {
        background-color: #ededed !important;
        color: #00e07b !important;
        cursor: pointer;
        font-weight: bold;
        outline: 0;
        min-width: 190px !important;
        width: 190px !important;
    }

    ngx-select-choices {
        min-width: 190px !important;
        width: 190px !important;
    }

    .dropdown-menu {
        min-width: 190px !important;
        width: 190px !important;
    }

    .form-control {
        border: none;
        width: 160px !important;
    }

    .form-control:focus {
        box-shadow: none !important;
    }

}

.change-days {

    ngx-select.change-days {
        display: inline-block;
        width: 80px !important;

    }

    .ngx-select__clear {
        margin-right: 10px;
        padding: 0;
        border: none;
        height: 20px !important;
        text-align: right;
        min-width: 100px !important;
    }

    .ngx-select__toggle {
        outline: 0;
        position: relative;
        text-align: left !important;
        color: #333;
        background-color: #fff;
        border-color: #ccc;
        display: flex;
        align-items: center !important;
        justify-content: space-between;
        margin: 0;
        min-width: 80px !important;
        width: 80px !important;
        max-height: 20px !important;
        border: none;
        font-size: 14px !important;
        padding: 0;
    }

    .ngx-select__choices {
        width: 100px !important;
        height: auto;
        max-height: 184px;
        overflow-x: hidden;
        margin-top: 0;
        position: absolute;
    }

    .ngx-select__toggle:hover {
        color: #00e07b !important;
    }

    .ngx-select__item {
        font-size: 14px;
        min-width: 100px !important;
        width: 100px !important;

        @media (max-width: 967px) {
            font-size: 12px;
        }
    }

    .ngx-select__item_active {
        background-color: #ededed !important;
        color: #00e07b !important;
        cursor: pointer;
        font-size: 14px;
        font-weight: bold;
        outline: 0;
        min-width: 100px !important;
        width: 100px !important;

        @media (max-width: 967px) {
            font-size: 12px;
        }
    }

    ngx-select-choices {
        min-width: 100px !important;
        width: 100px !important;
    }

    .dropdown-menu {
        min-width: 100px !important;
        width: 100px !important;
    }

    .form-control {
        border: none;
        font-size: 14px;
        width: 80px !important;
        height: 18px !important;
        min-height: 18px !important;
        max-height: 18px !important;
    }

    .form-control:focus {
        box-shadow: none !important;
    }

}

.ngx-select__clear {
    margin-right: 10px;
    padding: 0;
    border: none;
    height: auto !important;
    text-align: right;
    min-width: auto !important;
}

.ngx-select__toggle {
    outline: 0;
    position: relative;
    text-align: left !important;
    color: #333;
    background-color: #fff;
    border-color: #ccc;
    display: flex;
    align-items: center !important;
    justify-content: space-between;
    font-size: 14px !important;
    margin: 0;
    line-height: normal !important;
    min-width: 150px !important;
   

    @media (max-width: 967px) {
        min-width: 110px !important;
        width: 110px !important;
        font-size: 14px !important;
        padding: 5px;
        min-height: 32px !important;
    }
}

.ngx-select__toggle:hover {
    background-color: #ededed !important;
    color: #00e07b !important;
    border-color: #adadad;
}

.ngx-select__item_active {
    background-color: #ededed !important;
    color: #00e07b !important;
    cursor: pointer;
    font-weight: bold;
    outline: 0;
    font-size: 14px !important;
    min-width: 150px !important;
    width: 100% !important;

    @media (max-width: 967px) {
        min-width: 110px !important;
        width: 100% !important;
        font-size: 14px !important;
        padding: 5px;
        min-height: 26px !important;
    }
}

.dropdown-toggle {
    white-space: nowrap;
    color: #00e07b;
}

.ngx-select__choices {
    min-width: auto !important;
    width: auto !important;
}

.ngx-select__item {
    padding: 3px 6px !important;

    @media (max-width: 967px) {
        font-size: 14px;
    }
}

.dataTables_filter input:focus {
    background-image: linear-gradient(#00e07b, #00e07b), linear-gradient(#b1b8bb, #b1b8bb);
    box-shadow: none !important;
}

.panel-title {
    display: inline-block !important;
    margin-bottom: 0 !important;
    vertical-align: middle;
}

.form-material .form-control,
.form-material .form-control.focus,
.form-material .form-control:focus {
    width: 100% !important;
}

.ngx-select__search.form-control {
    min-width: 150px;
    font-size: 14px !important;

    @media (max-width: 967px) {
        min-width: 110px !important;
        min-height: 32px !important;
        height: 35px !important;
    }
}

.form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #00e07b;
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(0, 224, 123, .25) !important;
}

.select-type {
    min-width: 150px;
    display: inline-block;
    margin-left: 10px;

    @media (max-width: 967px) {
        min-width: 110px !important;
     
        font-size: 14px;
        padding: 5px;
        min-height: 32px !important;
    }
}

.select-type-min {
    min-width: 150px;
    display: inline-block;
    margin-left: 4px;

    @media (max-width: 967px) {
        min-width: 110px !important;
        width: 110px !important;
        font-size: 14px;
        padding: 5px;
        min-height: 32px !important;
    }
}

.select-type-no {
    min-width: 150px;
    display: inline-block;
    margin-left: 0px !important;
}

.select-type-margin {
    min-width: 190px;
    display: inline-block;
    margin-left: 10px;

    @media (max-width: 967px) {
        min-width: 110px !important;
        width: 110px !important;
        font-size: 14px;
        padding: 5px;
        min-height: 32px !important;
    }
}

.select-type-status {
    min-width: 190px;
    display: inline-block;
    margin-left: 10px;

    @media (max-width: 967px) {
        min-width: 110px !important;
        width: 110px !important;
        font-size: 14px;
        padding: 5px;
        min-height: 32px !important;
    }
}

.multiselect-dropdown .dropdown-btn .dropdown-multiselect__caret:before {
    position: relative;
    right: 0;
    top: 65%;
    color: #00e07b !important;
    margin-top: 4px;
    border-style: solid;
    border-width: 5px 5px 0 !important;
    border-color: #00e07b transparent !important;
    content: "";
}

.multiselect-dropdown .dropdown-btn .dropdown-multiselect__caret {
    position: relative;
    right: 0;
    top: 65%;
    color: #00e07b !important;
    margin-top: 4px;
    border-style: solid;
    border-width: 0px 5px 0 !important;
    border-color: #00e07b transparent !important;
    content: "";
}

.multiselect-dropdown .dropdown-btn .selected-item-container .selected-item {
    border: 1px solid #00e07b !important;
    margin-right: 4px;
    background: #00e07b !important;
    font-size: 14.5px;
    padding: 0 5px;
    color: #fff;
    border-radius: 2px;
    float: left;
    max-width: 100px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.multiselect-dropdown .dropdown-btn {
    display: inline-block;
    border: 1px solid #ccc !important;
    width: 100%;
    padding: .375rem .5rem !important;
    margin-bottom: 0;
    font-weight: 400;
    line-height: 1.52857143;
    text-align: left;
    vertical-align: middle;
    cursor: pointer;
    background-image: none;
    border-radius: 4px;
    height: 38px !important;
}

.multiselect-item-checkbox input[type=checkbox]+div:before {
    color: #00e07b !important;
    border: 2px solid #00e07b !important;
}


.multiselect-item-checkbox input[type=checkbox]:checked+div:before {
    animation: none !important;
    background: #00e07b !important;
}

.multiselect-item-checkbox input[type=checkbox]+div:hover {
    color: #00e07b !important;
}

.dropdown-list {
    padding-top: 0px !important;
    margin-top: 0px !important;
    min-width: 410px;
}

.row-green {
    background: #00e07b !important;
}

.row-white {
    background: white !important;
}

.md-drppicker td.active,
.md-drppicker td.active:hover {
    background-color: #00e07b !important;
    border-color: transparent;
    color: black !important;
}

.md-drppicker td.in-range {
    background-color: #dde2e4;
    border-color: transparent;
    color: black !important;
    border-radius: 0;
}

.md-drppicker .btn {
    position: relative;
    overflow: hidden;
    border-width: 0;
    outline: none;
    padding: 0 6px;
    cursor: pointer;
    border-radius: 2px;
    box-shadow: 0 1px 4px #0009;
    background-color: #00e07b !important;
    font-size: 14px;
    color: black !important;
    transition: background-color .4s;
    height: auto;
    text-transform: inherit !important;
    line-height: 36px;
    border: none;
}

.md-drppicker .ranges ul li button.active {
    background-color: #00e07b !important;
    color: black !important;
}

.swal2-actions.swal2-loading .swal2-styled.swal2-confirm {
    border-left-color: rgb(0, 224, 123) !important;
    border-right-color: rgb(0, 224, 123) !important;
}

.swal2-loader {
    border-color: #00e07b rgba(0, 0, 0, 0) #00e07b rgba(0, 0, 0, 0) !important;
}

.header-c {
    margin-top: 10px;
    display: inline-block;
    width: 100%;
    text-align: center;

    @media (max-width: 967px) {
        font-size: 14px;
        margin-top: 10px;
    }
}

.info {
    color: #9b9b9b;
}

h5 {
    @media (max-width: 967px) {
        font-size: 14px !important;
    }
}

.height-p {
    height: 560px;

    @media (max-width: 967px) {
        height: calc(100% - 10px);
    }
}

.height-panel {
    height: calc(90vh - 60px);

    @media (max-width: 967px) {
        height: calc(100% - 10px);
    }
}

.height-panel-m {
    height: calc(90vh - 60px);

    @media (max-width: 967px) {
        height: calc(100% - 10px);
    }
}

.ngx-select__disabled {
    background-color: #eceeef;
    border-radius: 4px;
    position: absolute;
    width: 82% !important;
    height: 100%;
    z-index: 5;
    opacity: .6;
    top: 0;
    left: 0;
    cursor: not-allowed;
}

.select-national {
    font-size: 1rem !important;
    margin: 0;
    min-width: 150px !important;
    width: 150px !important;
    outline: 0;
    position: relative;
    text-align: left !important;
    color: #333 !important;
    background-color: #fff;
    display: inline-flex;
    justify-content: space-between;
    height: 38px;
    font-weight: 400 !important;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    border: 1px solid #ccc;
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.expand {
    color: gray;
    border: 1px solid gray;
    border-radius: 5px;
    padding: 3px;
    font-size: 8px;
    cursor: pointer;

    &:hover {
        color: #00e07b !important;
        border: 1px solid #00e07b;
    }
}

.expand-big {
    color: gray;
    border: 1px solid gray;
    border-radius: 5px;
    padding: 3px;
    font-size: 14px;
    cursor: pointer;

    &:hover {
        color: #00e07b !important;
        border: 1px solid #00e07b;
    }
}

.modal-title {
    width: -webkit-fill-available !important;
}

.black-h {
    color: white !important;
    background: black !important;
    border: none !important;
}


.l-green-h {
    color: black !important;
    background: #c6e0b4 !important;
    border: none !important;
}

.l-blue-h {
    color: black !important;
    background: #bdd7ee !important;
    border: none !important;
}

.toggle-btn {
    display: inline-block;
    outline: 0;
    width: 3em;
    height: 1.5em;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    background: #fbfbfb;
    border-radius: 1.5em;
    padding: 2px;
    transition: all 0.4s ease;
    border: 1px solid #e8eae9;
}

.toggle-btn:focus::after,
.toggle-btn:active::after {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1),
        0 4px 0 rgba(0, 0, 0, 0.08),
        inset 0px 0px 0px 1.5px #9c9c9c;
}

.toggle-btn::after {
    left: 0;
    position: relative;
    display: block;
    content: '';
    width: 50%;
    height: 100%;
    border-radius: 1.5em;
    background: #fbfbfb;
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275),
        padding 0.3s ease, margin 0.3s ease;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1),
        0 4px 0 rgba(0, 0, 0, 0.08);
}

.toggle-btn.toggle-btn-on::after {
    left: 50%;
}

.toggle-btn.toggle-btn-on {
    background: #86d993;
}

.toggle-btn.toggle-btn-off {
    background: #86d993;
}

.toggle-btn.toggle-btn-on:active {
    box-shadow: none;
}

.toggle-btn.toggle-btn-on:active::after {
    margin-left: -0.8em;
}

.toggle-btn:active::after {
    padding-right: 0.8em;
}

.toggle-input {
    display: none;
}

.toggle-text {
    display: inline-flex;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    border: 1px solid #ccc;
    padding: 7px 15px;
    border-radius: 5px;
    margin-left: 20px;
    color: black;
}

.toggle-text-opt {
    display: inline-flex;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    border: 0px solid #ccc;
    padding: 0px;
    border-radius: 5px;
    margin-left: 0px;
    color: black;


}

.toggle-notification {
    display: inline-flex;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    padding: 7px 15px;
    border-radius: 5px;

    .toggle-btn.toggle-btn-off {
        background: #e6e6e6 !important;
    }
}

.hour-box {
    width: 90%;
    margin-left: 10px;
    background: white !important;
    color: rgb(98, 98, 98);
    font-size: 14px;
    font-weight: 700;
    padding: 0;
    border: 1px solid #ccc !important;
    padding: 6.5px 6px;
    border-radius: 4px;
    margin-top: .5px;
}

.hour-box-national {
    width: 36%;
    margin-left: 10px;
    background: white !important;
    color: rgb(98, 98, 98);
    font-size: 14px;
    font-weight: 700;
    padding: 0;
    border: 1px solid #ccc !important;
    padding: 7px 6px;
    border-radius: 4px;
    margin-top: 0.5px;
}

[type="checkbox"]:checked:disabled+label:before {
    border-right: 2px solid #26a69a;
    border-bottom: 2px solid #26a69a;
}

.ngx-select__disabled {
    opacity: 0 !important;
}

.ngx-datatable.bootstrap.cells {
    .datatable-body .datatable-scroll {
        display: inline-block;
        max-height: calc(82vh - 80px) !important;
        overflow: scroll;
    }
}

.ngx-datatable.bootstrap.cells .datatable-body .datatable-body-row.active {
    background-color: red !important;
    border-bottom: 1px solid #124187 !important;
    border-top: 1px solid #124187 !important;
    color: #124187 !important;
    cursor: pointer;
    font-weight: bold;
}

.gm-style .gm-style-iw-c {
    top: 20px !important;
    max-height: 220px !important;
}

.gm-style .gm-style-iw-tc {
    top: 16px !important;
}

.gm-style .gm-style-iw-d {
    max-height: 220px !important;
}

.rule-multi {
    .multiselect-dropdown .dropdown-btn {
        font-size: 12px !important;
        line-height: 2 !important;
    }

    .multiselect-dropdown .dropdown-btn .selected-item-container .selected-item {
        font-size: 12px !important;
    }

    .dropdown-list {
        font-size: 12px !important;
    }

    .multiselect-dropdown .dropdown-btn .selected-item-container .selected-item {
        max-width: 140px !important;
    }
}

.rule-single {
    .multiselect-dropdown .dropdown-btn {
        font-size: 12px !important;
        line-height: 2 !important;
    }

    .multiselect-dropdown .dropdown-btn .selected-item-container .selected-item {
        font-size: 12px !important;
    }

    .dropdown-list {
        font-size: 12px !important;
    }

    .multiselect-dropdown .dropdown-btn .selected-item-container .selected-item {
        max-width: 90% !important;
    }
}

.modal-open .modal {
    overflow-x: hidden;
    overflow-y: hidden;
}


.gm-style-iw>.gm-style-iw-chr {
    display: none !important;
}

.gm-style-iw:hover>.gm-style-iw-chr {
    display: none !important;
}

@media (max-width: 468px) {
    h5 {
        font-size: 16px !important;
        font-weight: 700;
    }
   .tooltip-trigger> .difference{
        font-size: 14px !important;
    }
}

.text-danger-color {
    color: #dc3545 !important;
}


//TABLA SCROLL MAPS
.floating-cards .table-container {
    max-height: 260px; /* Altura máxima del contenedor */
    overflow-y: auto; /* Habilitar scroll vertical */
    border: 1px solid #dee2e6; /* Borde opcional */
    border-radius: 4px; /* Esquinas redondeadas opcionales */
    position: relative;
  }
  
  .floating-cards .table-container .table {
    width: 100%; /* Asegura que la tabla ocupe el ancho completo */
    border-collapse: collapse; /* Colapsa bordes para mejor visualización */
  }
  
  .floating-cards .table-container thead th,
  .table-container thead td {
    position: sticky; /* Hace que el encabezado sea fijo */
    top: 0; /* Fija el encabezado en la parte superior */
    z-index: 2; /* Asegura que el encabezado esté por encima del contenido */
    background-color: #fff; /* Color de fondo del encabezado */
    border-bottom: 2px solid #dee2e6; /* Borde inferior para el encabezado */
  }
  
  .floating-cards> .card > .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px !important;
    min-height: 400px;
  }

  .icon-text-container {
    display: flex;
    align-items: center; /* Centra verticalmente el icono y la distancia */
    justify-content: center; /* Centra todo horizontalmente en la celda */
    gap: 4px; /* Espacio entre el icono y el texto */
  }

  .icon-text-container-left {
    display: flex;
    justify-content: left; /* Centra todo horizontalmente en la celda */
    gap: 4px; /* Espacio entre el icono y el texto */
  }
  
  
  .icon-circle {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 15px;  /* Tamaño del círculo */
    height: 15px;
    border-radius: 50%;
    border: 2px solid; /* Se usará el color en cada clase específica */
  }

  .icon-circle i {
    font-size: calc(60%); /* Tamaño del icono relativo al círculo */
    color: inherit; /* Hereda el color del contenedor */
  }

  .flag-pemex > i{
    color: #018d52; /* Color del icono */
    font-size: 11px;
  }
  .flag-no-pemex > i{
    font-size: 11px;
  }
  
  .flag-white {
    border-color: #cfcfcf; /* Borde gris para áreas comerciales */
    color: #cfcfcf; /* Color del icono */
  }
  
  .front-area {
    border-color: rgb(255, 174, 0); /* Borde gris para áreas comerciales */
    color: rgb(255, 174, 0); /* Color del icono */
  }
  
  .trade-area {
    border-color: #929292; /* Borde gris para áreas comerciales */
    color: #929292; /* Color del icono */
  }

  .tooltip-container {
    display: flex;
    align-items: center;
    text-align: left !important;
    justify-content: flex-start !important;
    color: #000;
  }

  .info-label, .info-value {
    color: #000 !important;
  }

  .tbsib>tr>td{
    border-top: 0px solid #dee2e6 !important;
    color: #000;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  
  .tbsib>tr{
    border-bottom: 0px solid #dee2e6 !important;
  }

  .circle-color-micromarket{
    width: 13px;
    height: 13px;
    border-radius: 50%;
    border: 1px solid rgb(183 183 183);
  }

  td>.circle-color-micromarket{
    margin-top: 6px;
  }