
/*Theme Colors*/
/*bootstrap Color*/
/*Light colors*/
/*Normal Color*/
/*Extra Variable*/
/*******************
/*Top bar
*******************/
.topbar {
  background: #fff; }
  .topbar .top-navbar .navbar-header .navbar-brand .light-logo {
    display: none; }
  .topbar .top-navbar .navbar-nav > .nav-item > span {
    color: #f5f5f5; }
  .topbar .navbar-light .navbar-nav .nav-item > a.nav-link {
    color: #a6b7bf; }
    .topbar .navbar-light .navbar-nav .nav-item > a.nav-link:hover, .topbar .navbar-light .navbar-nav .nav-item > a.nav-link:focus {
      color: #1a1e25 !important; }

.logo-center .topbar .navbar-header {
  text-align: center;
  padding-left: 0px;
  -webkit-box-shadow: none;
  box-shadow: none; }

.logo-center .topbar .top-navbar .navbar-header .navbar-brand .dark-logo {
  display: none; }

.logo-center .topbar .top-navbar .navbar-header .navbar-brand .light-logo {
  display: inline-block;
  color: rgba(255, 255, 255, 0.8); }

/*******************
/*General Elements
*******************/
a {
  color: #f5f5f5; }

a.link:hover, a.link:focus {
  color: #f5f5f5 !important; }

.bg-theme {
  background-color: #f5f5f5 !important; }

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #f5f5f5;
  border-color: #f5f5f5; }

.right-sidebar .rpanel-title {
  background: #f5f5f5; }

.stylish-table tbody tr:hover, .stylish-table tbody tr.active {
  border-left: 4px solid #f5f5f5; }

.text-themecolor {
  color: #f5f5f5 !important; }

.profile-tab li a.nav-link.active,
.customtab li a.nav-link.active {
  border-bottom: 2px solid #f5f5f5;
  color: #f5f5f5; }

.profile-tab li a.nav-link:hover,
.customtab li a.nav-link:hover {
  color: #f5f5f5; }

/*******************
/*Buttons
*******************/
.btn-themecolor,
.btn-themecolor.disabled {
  background: #f5f5f5;
  color: #ffffff;
  border: 1px solid #f5f5f5; }
  .btn-themecolor:hover,
  .btn-themecolor.disabled:hover {
    background: #f5f5f5;
    opacity: 0.7;
    border: 1px solid #f5f5f5; }
  .btn-themecolor.active, .btn-themecolor:focus,
  .btn-themecolor.disabled.active,
  .btn-themecolor.disabled:focus {
    background: #028ee1; }

/*******************
/*sidebar navigation
*******************/
.card-no-border .left-sidebar,
.card-no-border .sidebar-nav {
  background: #1a1e25; }

.mini-sidebar .sidebar-nav {
  background: transparent; 
}

@media (min-width: 768px) {
  .mini-sidebar .sidebar-nav #sidebarnav > li > ul {
    background: #1c2128; } }

.sidebar-nav {
  background: #1a1e25; }

.user-profile .profile-text a {
  color: #687384 !important; }

.card-no-border .sidebar-footer {
  background: #1c2128; }

.label-themecolor {
  background: #f5f5f5; }

.sidebar-nav > ul > li.active > a {
  color: #00e07b;
   }
  .sidebar-nav > ul > li.active > a i {
    color: #00e07b; }

  .sidebar-nav ul li a.active, .sidebar-nav ul li a span.separator{
    color: white;
    cursor: unset;
    font-weight: bold;
  }

  .sidebar-nav ul li a.active, .sidebar-nav ul li a:hover span.separator {
    color: white;
    cursor: unset;
    line-height: 1.5;
    text-decoration: none; 
  }

  .sidebar-nav ul li a.active, .sidebar-nav ul li a:hover{
    font-weight: 400;
    line-height: 1.5;
    color: #00e07b;
    text-decoration: none; 
  }

  .sidebar-nav ul li a.active i, .sidebar-nav ul li a:hover i {
    font-weight: 400;
    line-height: 1.5;
    color:#00e07b;
    text-decoration: none; 
}

.sidebar-nav ul li.nav-small-cap {
  color: #687384; }

@media (min-width: 768px) {
  .mini-sidebar .sidebar-nav #sidebarnav > li:hover > a {
    background: #1c2128; } 
  }
